import {
  AffectedRowsResult,
  DataHubImage,
  ProductGroupInput,
  ProductGroupProductInput,
} from "../../types";

import { EditProductInformation, ProductGroupImage } from "./updateProductGroup";
import { gql } from "@apollo/client";

export const updateProductGroupProductsMutation = (
  hasEditedProductImages?: boolean,
  hasRemovedProductImages?: boolean,
  editedProductImages?: DataHubImage[] | undefined,
  hasAddedInformation?: boolean,
  editedProductDetails?: EditProductInformation[] | undefined,
  hasAddedProductImages?: boolean,
  addedProductImages?: DataHubImage[] | undefined,
  removedProductImages?: DataHubImage[] | undefined
) => {
  return gql`
	  mutation UpdateProductGroupProducts(
	    $id: uuid!
	    $productGroup: hasuradb_product_group_set_input!
	    $addedProducts: [hasuradb_product_group_product_insert_input!]!
      $editedProductInformationIds: [uuid!]
      ${
        hasAddedInformation
          ? `$editedProductDetails: [hasuradb_product_group_product_information_insert_input!]!`
          : ``
      }
      ${
        hasEditedProductImages && editedProductImages?.length !== 0 && !hasAddedProductImages
          ? `$editImages: [hasuradb_product_group_product_image_update_input!]`
          : ``
      }
      ${
        hasAddedProductImages && addedProductImages?.length !== 0
          ? `$addedProductImages: [hasuradb_product_group_product_image_insert_input!]!`
          : ``
      }
	  ) {
      insertProductGroupProduct(objects: $addedProducts) {
        affected_rows
      }
      
      ${editedProductDetails
        ?.map(
          (detail, index) => `
                updateProductGroupProductInformation_${index}: updateProductGroupProductInformation(
                  where: { productId: { _eq: "${detail?.productId}" }, language: { _eq: ${detail?.language} } }
                  _set: { name: "${detail?.name}", description: "${detail?.description}", language: ${detail?.language}}
                ) {
                  affected_rows
                }
              `
        )
        .join("\n")}
        
        ${
          hasEditedProductImages && !hasRemovedProductImages
            ? `${editedProductImages
                ?.map(
                  (image, index) => `
                  updateProductGroupProductImage_${index}: updateProductGroupProductImage(
                    where: { filename: { _eq: "${image?.filename}" } }
                    _set: {
                      filename: "${image?.filename}",
                      copyright: "${image?.copyright}",
                      coverPhoto: "${image?.coverPhoto}",
                      originalUrl: "${image?.originalUrl}",
                      originalHeight: "${image?.originalHeight}",
                      originalWidth: "${image?.originalWidth}",
                      largeUrl: "${image?.largeUrl}",
                      thumbnailUrl: "${image?.thumbnailUrl}",
                      altText: "${image?.altText !== null ? image?.altText : null}"
                    }
                  ) {
                    affected_rows
                  }
                `
                )
                .join("\n")}`
            : ""
        }
        
        ${
          hasAddedProductImages && addedProductImages?.length !== 0
            ? `insertProductGroupProductImage(objects: $addedProductImages) {
                  affected_rows
                }`
            : ""
        }

        ${
          hasAddedInformation
            ? `insertProductGroupProductInformation(objects: $editedProductDetails) {
                  affected_rows
                }`
            : ""
        }
        
        updateProductGroupByPk(pk_columns: { id: $id }, _set: $productGroup) {
          id
          name
          description
        }
        
        ${
          hasRemovedProductImages
            ? removedProductImages
                ?.map(
                  (img, index) => `
                deleteProductGroupProductImage_${index}: deleteProductGroupProductImage(
                  where: { filename: { _eq: "${img?.filename}" } }
                    ) {
                    affected_rows
                    }
                    `
                )
                .join("\n")
            : ""
        }
	  }
	`;
};

export type UpdateProductGroupProductsResult = {
  deleteProductGroupProduct: AffectedRowsResult;
  deleteProductGroupProductImage: AffectedRowsResult;
  deleteProductGroupProductInformation: AffectedRowsResult;
  insertProductGroupProduct: AffectedRowsResult;
  insertProductGroupProductImage: AffectedRowsResult;
  insertProductGroupProductInformation: AffectedRowsResult;
  updateProductGroupProductInformation: AffectedRowsResult;
  updateProductGroupProductImage: AffectedRowsResult;
};

export type UpdateProductGroupProductsVariables = {
  id: string;
  editedProductIds?: (string | undefined)[] | undefined;
  addedProducts: ProductGroupProductInput[];
  productGroup: ProductGroupInput;
  editedProductDetails?: EditProductInformation[];
  editedProductImages?: DataHubImage[];
  editedProductInformationIds?: (string | undefined)[] | undefined;
  addedProductImages?: ProductGroupImage[];
};
