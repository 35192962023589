import React, { useCallback, useEffect } from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { CompanyInformation, UserRole } from "../../types";
import { getHasuraRoleContext } from "../../utils/functions";
import { useMutation } from "@apollo/client";
import { CompanyList } from "../../types/companyTypes";
import updateCompanyBusinessIdAndOfficialNameQuery, {
  UpdateCompanyBusinessIdAndOfficialNameResult,
  UpdateCompanyBusinessIdAndOfficialNameVariables,
} from "../../graphqlQueries/updateCompanyBusinessIdAndOfficialName";

type UserManagementModalProps = {
  closeModal: (isOnSubmit?: boolean) => void;
  onCancel: () => void;
  onConfirm?: () => void;
  title?: string;
  text?: string;
  buttonText?: string;
  companyData?: CompanyList | null;
};

const CompanyManagementModal = ({
  closeModal,
  onCancel,
  companyData,
}: UserManagementModalProps) => {
  const { t } = useTranslation();
  const hookFormMethods = useForm<CompanyInformation>({
    defaultValues: {
      companyId: companyData?.ellipsis?.id ?? "",
      businessId: companyData?.businessId ?? "",
      companyName: companyData?.ellipsis?.officialName ?? "",
    },
  });
  const {
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors },
  } = hookFormMethods;

  const [updateCompanyInfo] = useMutation<
    UpdateCompanyBusinessIdAndOfficialNameResult,
    UpdateCompanyBusinessIdAndOfficialNameVariables
  >(updateCompanyBusinessIdAndOfficialNameQuery, {
    context: getHasuraRoleContext(UserRole.ManageDatahub),
    onCompleted: (data) => {
      if (data.UpdateCompanyBusinessIdAndOfficialName) {
        closeModal(true);
      }
    },
    onError: (error) => {
      console.error("Error updating company information:", error);
    },
  });

  useEffect(() => {
    if (companyData) {
      reset({
        companyId: companyData.ellipsis?.id || "",
        businessId: companyData.businessId || "",
        companyName: companyData.ellipsis?.officialName || "",
      });
    }
  }, [companyData, reset]);

  const handleValueChange = useCallback(
    (field: keyof CompanyInformation, value: string) => {
      setValue(field, value, { shouldValidate: true });
    },
    [setValue]
  );

  const onSubmit = async (values: CompanyInformation) => {
    const { companyId, businessId, companyName } = values;
    try {
      if (companyId) {
        await updateCompanyInfo({
          variables: {
            companyId,
            businessId,
            officialName: companyName,
          },
          context: getHasuraRoleContext(UserRole.ManageDatahub),
        });
      }
    } catch (e) {
      console.error("Error submitting form:", e);
    }
  };

  return (
    <Modal
      animation={false}
      show
      onHide={onCancel}
      centered
      aria-labelledby="user-management-modal-title"
    >
      <Modal.Header closeButton={true}>
        <h3 id="user-management-modal-title" className="m-0 p-0">
          {t("companyInfo.companyDetailsHeader")}
        </h3>
      </Modal.Header>
      <Modal.Body className="px-5 pb-4">
        <FormProvider {...hookFormMethods}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group>
              <Form.Label>{t("companyInfo.officialName")}*</Form.Label>
              <Form.Control
                type="text"
                // caused by older react-hook-form version
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                {...register("companyName", {
                  required: "validationErrors.required",
                })}
                defaultValue={companyData?.ellipsis?.officialName ?? ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleValueChange("companyName", e.target.value)
                }
                isInvalid={!!errors.companyName}
                className={errors.companyName ? "is-invalid" : ""}
              />
              <Form.Label className="mt-3">{t("companyInfo.businessId")}*</Form.Label>
              <Form.Control
                type="text"
                // caused by older react-hook-form version
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                {...register("businessId", {
                  required: "validationErrors.required",
                })}
                defaultValue={companyData?.businessId ?? ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleValueChange("businessId", e.target.value)
                }
                isInvalid={!!errors.businessId}
                className={errors.businessId ? "is-invalid" : ""}
              />
              <Form.Control
                type="hidden"
                defaultValue={companyData?.ellipsis?.id ?? ""}
                // caused by older react-hook-form version
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                {...register("companyId", {
                  required: "validationErrors.required",
                })}
              />
            </Form.Group>
            <Row className="m-0">
              <Button variant="light" className="col mr-3 mt-3" onClick={onCancel}>
                {t("common.cancel")}
              </Button>
              <Button variant="primary" className="col mt-3" type="submit">
                {t("common.save")}
              </Button>
            </Row>
          </Form>
        </FormProvider>
      </Modal.Body>
    </Modal>
  );
};

export default CompanyManagementModal;
