import { gql } from "@apollo/client";

export const updateCompanyBusinessIdAndOfficialName = gql`
  mutation UpdateCompanyBusinessIdAndOfficialName(
    $companyId: uuid!
    $businessId: String!
    $officialName: String!
  ) {
    UpdateCompanyBusinessIdAndOfficialName(
      companyId: $companyId
      businessId: $businessId
      officialName: $officialName
    ) {
      businessId
      officialName
    }
  }
`;

export type UpdateCompanyBusinessIdAndOfficialNameVariables = {
  companyId: string;
  businessId: string;
  officialName: string;
};

export type UpdateCompanyBusinessIdAndOfficialNameResult = {
  UpdateCompanyBusinessIdAndOfficialName: {
    businessId: string;
    officialName: string;
  };
};

export default updateCompanyBusinessIdAndOfficialName;
