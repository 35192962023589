import React, { useCallback, useEffect, useState } from "react";
import { Container } from "./Container";
import { getDatahubApiEndpoint, getHasuraRoleContext } from "../../utils/functions";
import Loading from "../Loading";
import { Button, Modal, Spinner } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { ApolloClientObject, CompanyInformation, JoinRequestStatus, UserRole } from "../../types";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import fetchOrganisationQuery, {
  crmOrganisation,
  fetchOrganisationQueryResult,
  fetchOrganisationVariables,
} from "../../graphqlQueries/fetchOrganisation";
import CreateCompanyAccountWizard from "../../pages/SignUpWizard/CreateCompanyAccountWizard";
import { useKeycloak } from "@react-keycloak/web";
import { COMPANY_PATH, DASHBOARD_PATH } from "../../utils/routeConstants";
import getCompanyNamesQuery, { CompaniesResult } from "../../graphqlQueries/getCompanyNames";
import { userInfoVar } from "../../utils/ApolloCache";
import {
  CompanyByBusinessIdResult,
  getCompaniesByBusinessIds,
} from "../../graphqlQueries/getCompanyId";
import joinCompany, {
  insertJoinRequests,
  JoinCompanyResult,
  JoinCompanyVariables,
  JoinRequestObject,
  JoinRequestResult,
} from "../../graphqlQueries/joinCompany";
import getJoinRequests, {
  getJoinRequestsForUser,
  GetJoinRequestsResponse,
  GetJoinRequestsVariables,
} from "../../graphqlQueries/getJoinRequests";
import { BasicModal } from "../DataHubModal";
import processJoinRequestQuery, {
  ProcessJoinRequestResult,
  ProcessJoinRequestVariables,
} from "../../graphqlQueries/processJoinRequest";
import { Company } from "../../types/companyTypes";

export const fetchOrganisation = async (
  businessId: string,
  apolloClient: ApolloClientObject,
  userRole: UserRole
): Promise<fetchOrganisationQueryResult | undefined> => {
  try {
    const result = await apolloClient.query<
      fetchOrganisationQueryResult,
      fetchOrganisationVariables
    >({
      query: fetchOrganisationQuery,
      variables: {
        businessId: businessId,
      },
      context: getHasuraRoleContext(userRole),
    });
    if (result.data?.FetchOrganisation?.status === "ok") {
      return result.data;
    } else {
      console.log("Invalid response status:", result.data?.FetchOrganisation?.status);
      return undefined;
    }
  } catch (e) {
    console.error("Fetch organization error:", e);
    return undefined;
  }
};

type JoinRequestModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  companyName: string;
  isLoading: boolean;
  showConfirmation: boolean;
};

const JoinRequestModal: React.FC<JoinRequestModalProps> = ({
  onClose,
  onSubmit,
  companyName,
  isLoading,
  showConfirmation,
}) => {
  const { t } = useTranslation();

  return (
    <BasicModal
      header={t("signup.companyExistsWizardJoinRequestButton")}
      onCancel={() => (showConfirmation ? null : onClose())}
      onProceed={() => (showConfirmation ? null : onSubmit())}
      proceedText={t("common.join")}
      cancelText={t("common.cancel")}
      centered
    >
      <div className="p-3">
        {isLoading ? (
          <div className="text-center py-4">
            <Spinner animation="border" variant="primary" />
            <p className="mt-3">{t("common.loading")}</p>
          </div>
        ) : showConfirmation ? (
          <div className="text-center py-4">
            <p className="font-heavy color-primary mb-3">
              {t("signup.companyExistsWizardRequestSentTitle")}
            </p>
            <p>{t("common.redirecting")}</p>
          </div>
        ) : (
          <p className="mb-3">
            {t("signup.companyExistsWizardJoinRequestText")} {companyName}
          </p>
        )}
      </div>
    </BasicModal>
  );
};

const generateJoinRequestObjects = (
  companyIdArray: Company[],
  userId: string,
  userEmail: string
): JoinRequestObject[] => {
  return companyIdArray.map((c) => {
    return { companyId: c.id, userId, userEmail };
  });
};

export const SelectAccount = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [companies, setCompanies] = useState<crmOrganisation[]>([]);
  const [companyInformation, setCompanyInformation] = useState<CompanyInformation>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCurator, setIsCurator] = useState(false);
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const apolloClient = useApolloClient();
  const userInfo = userInfoVar();
  const userBusinessId = keycloak.tokenParsed?.business_id as string[] | [];
  const [hasCompanyId, setHasCompanyId] = useState(!!keycloak.tokenParsed?.company_ids);
  const [joinRequestLoading, setJoinRequestLoading] = useState(false);
  const [showJoinRequestConfirmation, setShowJoinRequestConfirmation] = useState(false);
  const [showJoinRequestModal, setShowJoinRequestModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<{ id: string; name: string } | null>(null);
  const TIMEOUT_COUNT = 2000;

  const [joinCompanyMutation] = useMutation<JoinCompanyResult, JoinCompanyVariables>(joinCompany, {
    context: getHasuraRoleContext(UserRole.CreateCompany),
  });

  const [sendJoinRequests] = useMutation<JoinRequestResult, { objects: JoinRequestObject[] }>(
    insertJoinRequests
  );

  const [processJoinRequest] = useMutation<ProcessJoinRequestResult, ProcessJoinRequestVariables>(
    processJoinRequestQuery,
    { context: getHasuraRoleContext(UserRole.ManageCompany) }
  );

  const { refetch: refetchJoinRequests } = useQuery<
    GetJoinRequestsResponse,
    GetJoinRequestsVariables
  >(getJoinRequests, {
    variables: {
      userId: userInfo?.userId ?? "",
    },
    context: getHasuraRoleContext(UserRole.CreateCompany),
  });

  const handleJoinRequest = async () => {
    if (!userInfo || !selectedCompany) {
      return;
    }

    try {
      setJoinRequestLoading(true);

      await joinCompanyMutation({
        variables: {
          companyId: selectedCompany.id,
        },
        context: getHasuraRoleContext(UserRole.CreateCompany),
        refetchQueries: [{ query: getJoinRequestsForUser }],
      });

      const updatedJoinRequests = await refetchJoinRequests();

      console.log(updatedJoinRequests);

      const latestRequest = updatedJoinRequests.data?.companyJoinRequest.find(
        (request) => request.company.id === selectedCompany.id
      );

      if (!latestRequest) {
        await sendJoinRequests({
          variables: {
            objects: generateJoinRequestObjects(
              filteredCompanies as Company[],
              userInfo.userId,
              userInfo.email as string
            ),
          },
          refetchQueries: [getJoinRequestsForUser],
          context: getHasuraRoleContext(UserRole.CreateCompany),
        });
      }

      if (latestRequest) {
        const processResult = await processJoinRequest({
          variables: {
            id: latestRequest.id,
            approveRequest: true,
          },
          context: getHasuraRoleContext(UserRole.CreateCompany),
        });

        if (
          processResult.data?.ProcessJoinRequest.joinRequest.status === JoinRequestStatus.Approved
        ) {
          await keycloak.updateToken(-1);
          setJoinRequestLoading(false);
          setShowJoinRequestConfirmation(true);

          setTimeout(() => {
            window.location.href = `${COMPANY_PATH}/${selectedCompany.id}`;
          }, TIMEOUT_COUNT);
        } else {
          throw new Error("Join request was not approved");
        }
      }
    } catch (error) {
      console.error("Error processing join request:", error);
      if (error instanceof Error) {
        console.error("Error details:", error.message);
      }
      setShowJoinRequestModal(false);
      setSelectedCompany(null);
    } finally {
      setJoinRequestLoading(false);
    }
  };

  const companyCheck = useCallback(async () => {
    if (userInfo && userBusinessId) {
      try {
        setLoading(true);
        const businessIds = Array.isArray(userBusinessId) ? userBusinessId : [userBusinessId];

        console.log(businessIds);

        const fetchPromises = businessIds.map(async (businessId) => {
          console.log(businessId);
          const result = await fetchOrganisation(businessId, apolloClient, UserRole.CreateCompany);
          return result?.FetchOrganisation;
        });

        const results = await Promise.all(fetchPromises);

        const validResults = results.filter(
          (result): result is crmOrganisation => result !== undefined
        );

        console.log(validResults);

        setCompanies(validResults);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Companies not found for user.", error);
      }
    }
  }, [apolloClient, userInfo, userBusinessId]);

  useEffect(() => {
    void companyCheck();
  }, [companyCheck]);

  useEffect(() => {
    setHasCompanyId(!!keycloak.tokenParsed?.company_ids);
  }, [keycloak.tokenParsed?.company_ids, userInfo]);

  const handleOpen = (businessId: string, companyName: string, isCurator: boolean) => {
    if (isCurator) {
      setIsCurator(true);
    } else {
      setIsCurator(false);
    }
    setCompanyInformation({ businessId, companyName });
    setIsModalOpen(true);
  };

  const { data: usersCompanies } = useQuery<CompaniesResult>(getCompanyNamesQuery, {
    variables: {
      companyIds: keycloak.tokenParsed?.company_ids,
    },
    context: getHasuraRoleContext(UserRole.ManageCompany),
    fetchPolicy: "cache-first",
  });

  const { data: businessEntityCompanies } = useQuery<CompanyByBusinessIdResult>(
    getCompaniesByBusinessIds,
    {
      variables: {
        businessIds: userInfo?.businessId,
      },
      context: getHasuraRoleContext(UserRole.CreateCompany),
      fetchPolicy: "cache-first",
    }
  );

  const companyNames = usersCompanies?.company;

  const addUserToApiGroup = async () => {
    try {
      setLoading(true);

      const response = await fetch(`${getDatahubApiEndpoint()}/apiUser`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${keycloak?.token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to add user to API Users group");
      }
      window.location.href = `${DASHBOARD_PATH}`;
    } catch (error) {
      console.error("Error adding user to group:", error);
    } finally {
      setLoading(false);
    }
  };

  const getFilteredCompanies = (businessId: string) =>
    businessEntityCompanies?.businessEntity
      ?.find((b) => b.businessId === businessId)
      ?.companies?.filter((company) => {
        const existsInBusinessEntity = businessEntityCompanies?.businessEntity
          .find((b) => b.businessId === businessId)
          ?.companies?.some((c) => c.id === company.id);

        const notAlreadyMember = !companyNames?.some((cn) => cn.id === company.id);

        return existsInBusinessEntity && notAlreadyMember;
      });

  const filteredCompanies = businessEntityCompanies?.businessEntity[0]?.companies.filter(
    (company) => {
      const existsInBusinessEntity = businessEntityCompanies.businessEntity[0].companies.some(
        (c) => c.id === company.id
      );
      const notAlreadyMember = !companyNames?.some((cn) => cn.id === company.id);

      return existsInBusinessEntity && notAlreadyMember;
    }
  );

  return (
    <Container>
      <div>
        <h3 className="text-landing-h4 mb-5 text-center">
          {t("signup.companyExistsWizardHeader")}
        </h3>
        {loading ? (
          <Loading />
        ) : (
          companies
            ?.filter((company) => company.businessid !== null)
            .map(({ businessid, name, marketing_name, accountsite_id }, i) => (
              <React.Fragment key={businessid ?? accountsite_id}>
                <div className="mt-4 border-bottom-gray-300 pb-4">
                  <div className="mt-4 mb-4">
                    <MainCompany
                      title={t("signup.companyExistsWizardBusinessName")}
                      content={name ?? "-"}
                    />
                    <MainCompany
                      title={t("signup.companyExistsWizardBusinessId")}
                      content={businessid ?? "-"}
                    />
                    <MainCompany
                      title={t("companyInfo.marketingName")}
                      content={marketing_name ?? "-"}
                    />
                  </div>
                  <div className="mb-4">
                    {companyNames && companyNames?.length > 0 && i < 1 && (
                      <>
                        <p className="mt-3 mb-3 font-heavy">
                          {t("signup.companyExistsWizardCompaniesJoined")}
                        </p>
                        <div className="mb-3">
                          {companyNames?.map((company, key) => (
                            <Button
                              key={key}
                              className="w-100 mb-2"
                              variant="outline-primary"
                              onClick={() => {
                                if (!hasCompanyId) {
                                  handleOpen(businessid, marketing_name ?? name, false);
                                } else {
                                  window.location.href = `${COMPANY_PATH}/${company.id}`;
                                }
                              }}
                            >
                              {company.businessName}
                            </Button>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="mb-4">
                    {getFilteredCompanies(businessid) &&
                      // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
                      getFilteredCompanies(businessid)!.length > 0 && (
                        <>
                          <p className="mt-3 mb-3 font-heavy">
                            {t("signup.companyExistsWizardCompaniesFound")}
                          </p>
                          <div className="mb-3">
                            {userInfo &&
                              getFilteredCompanies(businessid)?.map((company) => (
                                <Button
                                  key={company.id}
                                  className="w-100 mb-2"
                                  variant="outline-primary"
                                  onClick={() => {
                                    setSelectedCompany({
                                      id: company.id,
                                      name: company.businessName,
                                    });
                                    setShowJoinRequestModal(true);
                                  }}
                                >
                                  {company.businessName}
                                </Button>
                              ))}
                          </div>
                        </>
                      )}
                  </div>
                  {showJoinRequestModal && selectedCompany && (
                    <JoinRequestModal
                      isOpen={showJoinRequestModal}
                      onClose={() => {
                        if (!joinRequestLoading && !showJoinRequestConfirmation) {
                          setShowJoinRequestModal(false);
                          setSelectedCompany(null);
                        }
                      }}
                      onSubmit={handleJoinRequest}
                      companyName={selectedCompany.name}
                      isLoading={joinRequestLoading}
                      showConfirmation={showJoinRequestConfirmation}
                    />
                  )}
                  <p className="mb-4 mt-4">{t("signup.companyExistsWizardAddNewCompanyText")}</p>
                  <div className="d-flex flex-column align-items-center">
                    <Button
                      className="w-100 mb-2"
                      variant="outline-secondary"
                      onClick={() => handleOpen(businessid, marketing_name ?? name, false)}
                    >
                      {t("signup.companyExistsWizardAddNewCompanyButton")}
                    </Button>
                    <Button
                      className="w-100 mb-2"
                      variant="outline-secondary"
                      onClick={() => handleOpen(businessid, marketing_name ?? name, true)}
                    >
                      {t("signup.companyExistsWizardAddNewCuratorButton")}
                    </Button>
                  </div>
                </div>
                <Modal
                  show={isModalOpen}
                  size="lg"
                  centered
                  animation={false}
                  onHide={() => setIsModalOpen(false)}
                >
                  <div className="flex m-5">
                    <CreateCompanyAccountWizard
                      companyInformation={companyInformation}
                      isCurator={isCurator}
                    />
                  </div>
                </Modal>
              </React.Fragment>
            ))
        )}
        {userBusinessId?.length === 0 || userBusinessId === undefined || userBusinessId === null ? (
          <div className="mt-4 border-bottom-gray-300 pb-4 text-center">
            <p className="col font-heavy p-0">{t("signup.loginAsApiUser")}</p>
            <Button
              id="apiBtn"
              className="col col-6 mt-3"
              variant="light"
              onClick={addUserToApiGroup}
            >
              {t("signup.loginToDatahub")}
            </Button>
          </div>
        ) : undefined}
        <p className="mt-4 px-3 text-smaller color-gray-800 text-center">
          <Trans i18nKey="signup.wrongBusinessIdDescription">
            if you have registered i18n placeholder
            <a href="mailto:datahub@visitfinland.com">datahub@visitfinland.com</a>
          </Trans>
        </p>
      </div>
    </Container>
  );
};

const MainCompany = ({ title, content }: { title: string; content: string }) => (
  <div className="d-flex flex-row">
    <span className="col col-4 p-0 mr-3">{title}</span>
    <span className="col col-8 font-heavy p-0">{content}</span>
  </div>
);
